import React, { forwardRef, useImperativeHandle, useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react-lite";
import Button from "react-bootstrap/Button";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Spinner from "react-bootstrap/Spinner";

import { DropdownButtonAppended, SeparatorWrapper } from "./ConfigureArbsModal.styled";
import { useSettings } from "../../hooks";
import { Trans, useTranslation } from "react-i18next";

// This is "Guardado de presets"
// Use this component to manage the settings (create, clone, delete...)
export const ConfigureArbsSettings = observer(forwardRef(
  function ConfigureArbsSettings({ closeModal, formik, formLoading, setFormLoading }, ref) {
    const { schema, settings, setSettingsById, setSettingsIdx, settingsList, storage } = useSettings();
    const [settingsName, setSettingsName] = useState(settings?.name || "");
    const { t } = useTranslation();
    const settingsListOrdered = useMemo(
      () => settingsList.map((settings, idx) => [settings, idx]).sort((a, b) => a[0].name.localeCompare(b[0].name)),
      [settingsList]
    );

    // Update settingsName when settings change
    useEffect(() => setSettingsName(settings.name), [settings]);

    // Expose these code to the parent (by using the reference)
    useImperativeHandle(ref, () => ({
      // This corresponds to the "Guardar" button
      async saveSettings(configuration) {
        setFormLoading && setFormLoading(true);
        const newSettings = { ...settings, name: settingsName, configuration }
        try {
          await storage.saveSettings(newSettings);
          closeModal();
        } catch (error) {
          // TODO: use something fancier like a Toast
          alert("Error occured when saving the configuration");
          console.error(error);
        }
        setFormLoading && setFormLoading(false);
      },
    }));

    const deleteSettings = async () => {
      setFormLoading && setFormLoading(true);
      try {
        await storage.deleteSettings();
      } catch (error) {
        // TODO: use something fancier like a Toast
        alert("Error occured when deleting settings");
        console.error(error);
      }
      setFormLoading && setFormLoading(false);
    };

    const resetConfiguration = () => {
      formik.resetForm({ values: schema.defaultConfiguration });
    };

    const saveClonedSettings = async () => {
      setFormLoading && setFormLoading(true);
      try {
        const clonedSettings = await storage.saveNewSettings({
          ...settings,
          name: settingsName,
          defaultConfiguration: false,
        });
        setSettingsById(clonedSettings?._id?.id); // select new settings
      } catch (error) {
        // TODO: use something fancier like a Toast
        alert("Error occured when saving the configuration");
        console.error(error);
      }
      setFormLoading && setFormLoading(false);
    };

    const saveNewSettings = async () => {
      setFormLoading && setFormLoading(true);
      try {
        const newSettings = await storage.saveNewSettings();
        setSettingsById(newSettings?._id?.id); // select new settings
      } catch (error) {
        // TODO: use something fancier like a Toast
        alert("Error occured when saving the configuration");
        console.error(error);
      }
      setFormLoading && setFormLoading(false);
    };

    const saveSettingsAsDefault = async () => {
      setFormLoading && setFormLoading(true);
      try {
        await storage.saveSettingsAsDefault();
      } catch (error) {
        // TODO: use something fancier like a Toast
        alert("Error occured when saving the configuration");
        console.error(error);
      }
      setFormLoading && setFormLoading(false);
    };

    return (
      <>
        <SeparatorWrapper className="mb-1">
          <hr style={{ background: "#264226" }}/>
          <Form.Label style={{ fontWeight: "bold" }}>
              {t('savePresets')} {formLoading && <Spinner animation="border" role="status" size="sm" />}
            </Form.Label>
            <hr style={{ background: "#264226" }}/>
        </SeparatorWrapper>

        <InputGroup>
          <Form.Control type="text" value={settingsName} onChange={(e) => setSettingsName(e.target.value)} />

          <InputGroup.Append>
            <DropdownButton variant="secondary" title={t('change')} bsPrefix="rounded-0 btn">
              {settingsListOrdered.map(([_settings, idx]) => (
                <Dropdown.Item
                  key={_settings?._id?.id || idx}
                  active={_settings === settings}
                  onClick={() => setSettingsIdx(idx)}
                >
                  {_settings.defaultConfiguration ? (
                    <span title={t('defaultConfiguration')}>
                      {_settings.name} <span className="text-info">&#9733;</span>
                    </span>
                  ) : (
                    _settings.name
                  )}
                </Dropdown.Item>
              ))}
            </DropdownButton>
            <Button variant="primary" type="submit">
              {t('save')}
            </Button>
            <DropdownButtonAppended variant="secondary" title="">
              {settingsList.length > 1 && (
                <>
                  <Dropdown.Item onClick={deleteSettings}>{t('remove')}</Dropdown.Item>
                  <Dropdown.Divider />
                </>
              )}
              <Dropdown.Item onClick={resetConfiguration}>{t('reset')}</Dropdown.Item>
              {!settings.defaultConfiguration && (
                <Dropdown.Item onClick={saveSettingsAsDefault}>{t('useAsDefault')}</Dropdown.Item>
              )}
              <Dropdown.Item onClick={saveClonedSettings}>{t('duplicate')}</Dropdown.Item>
              <Dropdown.Item onClick={saveNewSettings}>{t('new')}</Dropdown.Item>
            </DropdownButtonAppended>
          </InputGroup.Append>
        </InputGroup>
      </>
    );
  },
  { forwardRef: true }
));
