import React, { useMemo, useRef, useState } from "react";
import { observer } from "mobx-react-lite";
import _isEmpty from "lodash/isEmpty";
import _omit from "lodash/omit";
import _uniq from "lodash/uniq";
import _without from "lodash/without";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { Form as FormikForm, Formik } from "formik";
import { useTranslation } from 'react-i18next';

import { getSubscriptions } from "../../utils/subscriptions";
import { SeparatorWrapper, LabelSelectAll, LabelShowClones, BookieBadge, WideBadge, BookiesContainer, LabelsContainer, SelectionRow, BookieSearchContainer, BookieSearchInputContainer, BookieListContainer, ClonesContainer, MainBookieContainer, Clone2Container, CopyFromButton, CountriesSelectorContainer } from "./ConfigureArbsModal.styled";
import { ConfigureArbsFormSubscription } from "./ConfigureArbsFormSubscription";
import { ConfigureArbsFormBookie } from "./ConfigureArbsFormBookie";
import { ConfigureArbsSettings } from "./ConfigureArbsSettings";
import { HelpButton } from "../HelpButton/HelpButton";
import { DisabledBookieCircle } from "../DisabledBookieCircle/DisabledBookieCircle";
import { useSettings } from "../../hooks";

function copySubscriptionConfiguration(selectedSubscription, selectedSubscriptionToCopy, formik) {
  const config = formik.values.subscriptionConfigs[selectedSubscriptionToCopy];
  formik.setFieldValue(`subscriptionConfigs.${selectedSubscription}`, config);
}

export const ConfigureArbsForm = observer(({ closeModal }) => {
  const settingsRef = useRef();
  const [formLoading, setFormLoading] = useState(false);
  const [bookiesByCountry, setBookiesByCountry] = useState("*");
  const [searchBookies, setSearchBookies] = useState("");
  const [showClones, setShowClones] = useState(false);
  const { options, schema, settings, isLive, isPre } = useSettings();
  const configuration = settings?.configuration;
  const [selectedBookies, setSelectedBookies] = useState(configuration.bookies);
  const { t } = useTranslation();

  const availableCountries = [
    {
      value: "ES",
      label: t('Spain'),
    },
    {
      value: "UK",
      label: t('UK'),
    },
    {
      value: "MX",
      label: "Mexico",
    }
  ]


  function isBookieVisible(bookie, bookiesByCountry) {
    // filter bookies by country
    return (
      bookiesByCountry === "*" ||
      (bookiesByCountry === "O" && !_isEmpty(_without(bookie.countries, "ES"))) ||
      (availableCountries.some(country => country.value === bookiesByCountry) && (bookie.countries.includes(bookiesByCountry) || (bookie.clones || []).some(clone => clone.countries.includes(bookiesByCountry))))
    );
  }

    // Select / deselect all bookies (only affects the array of passed bookies)
  function onSelectAllBookies(event, formik, affectedBookies) {
    const { checked } = event.target; // the value of checked has already changed by the click
    const { bookies = [], bookiesReference = [] } = formik.values;
    let newBookies;
    if (checked) {
      newBookies = _uniq(bookies.concat(affectedBookies.map((bookie) => bookie.bookie)));
      formik.setValues(
        {
          ...formik.values,
          bookies: newBookies,
        },
        false
      );
    } else {
      newBookies = bookies.filter(
        (bookieName) => !affectedBookies.find((visibleBookie) => bookieName === visibleBookie.bookie)
      );
      formik.setValues(
        {
          ...formik.values,
          bookies: newBookies,
          bookiesReference: bookiesReference.filter(
            (bookieName) => !affectedBookies.find((visibleBookie) => bookieName === visibleBookie.bookie)
          ),
        },
        false
      );
    }
    setSelectedBookies(newBookies); // update the selectedBookies state
  }

  function isAvailableToSelect({ bookie }) {
    const mainBookie = bookie.split(":").length > 1 ? bookie.split(":")[0] : bookie;

    return (
      selectedBookies.some(_bookie => _bookie === bookie ) ||
      selectedBookies.every(_bookie => (_bookie.split(":").length > 1 ? _bookie.split(":")[0] : _bookie) !== mainBookie)
    );
  }


  // ADD CLONES COUNTRIES 
  // ON TEXT SEARCH APART FROM MAIN BOOKIE ALSO LOOK FOR CLONES
  // IF BOOKIE IS SELECTED THEN SHOW IN VISIBLE BOOKIES

  const visibleBookies = useMemo(
    () => options.bookies
    .filter((bookie) => 
      (bookie.removed ? selectedBookies.some(_bookie => _bookie === bookie.bookie) : true ) &&
      (searchBookies.length > 0 ? true : isBookieVisible(bookie, bookiesByCountry)) && 
      (bookie.label.toLowerCase().includes(searchBookies.toLowerCase()) || (bookie.clones || []).some(({ bookie }) => bookie.toLowerCase().includes(searchBookies.toLocaleLowerCase())))
    ),
    [options.bookies, bookiesByCountry, searchBookies, showClones, selectedBookies]
  );

  function onBookieChange(event, formik) {
    const { name: bookie } = event.target;
    let { bookies = [], bookiesReference = [], bookiesConfigs = {}, subscriptionConfigs = {} } = formik.values;
    if (bookiesReference.includes(bookie)) {
      bookiesReference = _without(bookiesReference, bookie);
      bookies = _without(bookies, bookie);
      delete bookiesConfigs[bookie];
      Object.keys(subscriptionConfigs).forEach((subscription) => {
        if (subscription.includes(bookie)) delete subscriptionConfigs[subscription];
      });
      formik.setFieldValue("bookiesReference", bookiesReference, false);
      formik.setFieldValue("bookies", bookies, false);
      formik.setFieldValue("bookiesConfigs", bookiesConfigs, false);
      formik.setFieldValue("subscriptionConfigs", subscriptionConfigs, false);
    } else if (bookies.includes(bookie)) {
      bookiesReference = bookiesReference.concat(bookie);
      formik.setFieldValue("bookiesReference", bookiesReference, false);
    } else {
      bookies = bookies.concat(bookie);
      formik.setFieldValue("bookies", bookies, false);
    }
    setSelectedBookies(bookies); // update the selectedBookies state
  }

  const getSettingsFromFormikValues = (values) => {
    // discard fields from the form that are not saved in the configuration
    const newConfiguration = _omit(values, ["selectedSubscription", "subscriptionToCopy", "selectedBookie"]);
    return newConfiguration;
  };

  // Return the subscriptions formatted and sorted to be used as options in a select
  const getSubscriptionsForOptions = (configuration) => {
    const { bookiesReference = [] } = configuration;
    const subscriptions = getSubscriptions(configuration);
    const subscriptionsForOptions = subscriptions
      .map((subscription) => {
        const [bookie1, bookie2] = subscription.split("-");
        let arb;
        if (bookiesReference.includes(bookie1)) {
          if (bookiesReference.includes(bookie2)) arb = [bookie1, bookie2].sort();
          else arb = [bookie1, bookie2];
        } else if (bookiesReference.includes(bookie2)) arb = [bookie2, bookie1];
        else arb = [bookie1, bookie2].sort();
        let text = [options.bookieToLabel[arb[0]], options.bookieToLabel[arb[1]]].join(" ➞ ");
        if (configuration.subscriptionConfigs[subscription] !== undefined) text = "⚙️ " + text;
        return { subscription, text };
      })
      .sort(({ text: a }, { text: b }) => a.localeCompare(b));
    return { subscriptions, subscriptionsForOptions };
  };

  // Return the subscriptions formatted and sorted to be used as options in a select
  const getBookiesForOptions = (configuration) => {
    const { bookies = [] } = configuration;
    const bookiesForOptions = bookies
      .map(bookie => {
        let text = options.bookieToLabel[bookie];
        if (configuration.bookiesConfigs[bookie] !== undefined) text = "⚙️ " + text;
        return { bookie, text };
      })
      .sort(({ text: a }, { text: b }) => a.localeCompare(b));
    return { bookiesForOptions };
  };

  // This corresponds to the "Guardar" button
  const onFormSubmit = async (values) => {
    const newSettings = getSettingsFromFormikValues(values);
    settingsRef.current.saveSettings(newSettings);
  };

  function WideBadgeComponent({ bookie, label, disabled, removed, checked, isReference, onBookieChange, formik, nonSelectable, nonClone, warning }) {
    return (
      <BookieBadge key={bookie} checked={checked} isReference={isReference} forwardedAs="label" nonClone={nonClone} warning={warning}>
        <input
          type="checkbox"
          name={bookie}
          checked={checked}
          value={checked}
          onChange={(event) => onBookieChange(event, formik)}
          disabled={nonSelectable}
        />{" "}
        {label}
        { disabled && <DisabledBookieCircle id="bookies-help" placement="top" width="140px">
          La bookie se encuentra desactivada temporalmente
        </DisabledBookieCircle> }
        { removed && <DisabledBookieCircle id="bookies-help" placement="top" width="140px" color="black">
          La bookie se encuentra eliminada
        </DisabledBookieCircle> }
        { warning && <DisabledBookieCircle id="bookies-help" placement="top" width="140px" color="orange">
          La bookie está experimentando problemas
        </DisabledBookieCircle> }
      </BookieBadge>
    );
  }

  const getCloneFilter = (clone, bookie) => 
    (clone.removed ? selectedBookies.some(_bookie => _bookie === `${bookie}:${clone.bookie}`) : true ) &&
    (searchBookies.length > 0 ? true : (bookiesByCountry === '*' ? true : (clone.countries.includes(bookiesByCountry) || (bookiesByCountry === 'O' && clone.countries.every(country => !availableCountries.map(({ value }) => value).includes(country)))))) &&
    (clone.label.toLowerCase().includes(searchBookies.toLowerCase()));

  return (
    <Formik initialValues={configuration} onSubmit={onFormSubmit} enableReinitialize>
      {(formik) => {
        const { bookies = [], bookiesReference = [] } = formik.values;
        const { subscriptions, subscriptionsForOptions } = getSubscriptionsForOptions(formik.values);
        let selectedSubscription = formik.values.selectedSubscription;
        if (!subscriptions.includes(selectedSubscription)) selectedSubscription = "default";
        const isSelectAllBookiesChecked = useMemo(
          () => visibleBookies.every((visibleBookie) => bookies.includes(visibleBookie.bookie)),
          [bookies, visibleBookies]
          );
          
        const { bookiesForOptions } = getBookiesForOptions(formik.values);
        let selectedBookie = formik.values.selectedBookie;
        if (!bookies.includes(selectedBookie)) selectedBookie = "default";

        const onUseDefaultSubscriptionConfigChange = (event, subscription) => {
          const config = event.target.checked ? undefined : schema.defaultSubscriptionConfig;
          formik.setFieldValue(`subscriptionConfigs.${subscription}`, config);
        };

        const onUseDefaultBookieConfigChange = (event, bookie) => {
          const config = event.target.checked ? undefined : schema.defaultBookieConfig;
          formik.setFieldValue(`bookiesConfigs.${bookie}`, config);
        };

        return (
          <Form as={FormikForm}>
            <fieldset disabled={formLoading}>
              <SeparatorWrapper className="mb-1">
                <hr style={{ background: "#264226" }}/>
                <Form.Label style={{ fontWeight: "bold" }}>{t('globalConfiguration')}</Form.Label>
                <hr style={{ background: "#264226" }}/>
              </SeparatorWrapper>

              <BookiesContainer className="bookies-title" style={{ marginBottom: "10px" }}>
                <Form.Label>Bookies</Form.Label>
                <HelpButton id="bookies-help" placement="right-start" width="340px">
                  {t('bookiesHelp1')}
                  <br />
                  {t('bookiesHelp2')}
                  <ul>
                    {t('bookiesHelpArray1', { returnObjects: true }).map((item, index) => (
                      <li key={index}>{item}</li>
                    ))}
                  </ul>
                </HelpButton>
                <SelectionRow>
                <LabelSelectAll checked={isSelectAllBookiesChecked}>
                  <input
                    type="checkbox"
                    name="all-bookies"
                    checked={isSelectAllBookiesChecked}
                    onChange={(event) => onSelectAllBookies(event, formik, visibleBookies)}
                  />{" "}
                  {t('selectAll')}
                </LabelSelectAll>
                  <CountriesSelectorContainer>
                    <Form.Label style={{ marginBottom: 0, fontSize: '1.2rem', marginRight: '-10px' }}><i className="fa-solid fa-globe" /></Form.Label>
                    <Form.Control
                      as="select"
                      label="Bookies por país"
                      name="bookiesByCountry"
                      onChange={({ target }) => setBookiesByCountry(target.value)}
                      value={bookiesByCountry}
                      style={{ display: "inline", width: "auto", height: 33.25, marginLeft: '10px', background: "#1d2d1d", color: "white" }}
                    >
                      <option value="*">{t('allBookies')}</option>
                      {
                        availableCountries.map(({ value, label }) => (
                          <option value={value}>{label}</option>
                        ))
                      }
                      <option value="O">{t('otherCountries')}</option>
                    </Form.Control>
                    <LabelShowClones checked={showClones}>
                      <input
                        type="checkbox"
                        name="all-clones"
                        checked={showClones}
                        onChange={() => setShowClones(prevShowClones => !prevShowClones)}
                      />{" "}
                      {t('showClones')}
                    </LabelShowClones>
                  </CountriesSelectorContainer>
                  <BookieSearchContainer>
                    <BookieSearchInputContainer>
                      <Form.Control
                        type="text"
                        placeholder={t('filterBookies')}
                        onChange={({ target }) => setSearchBookies(target.value)}
                      />
                    </BookieSearchInputContainer>
                    <Form.Label style={{ marginBottom: 0 }}><i className="fa-solid fa-magnifying-glass" /></Form.Label>
                  </BookieSearchContainer>
                </SelectionRow>
                <BookieListContainer role="group" aria-labelledby="checkbox-group" style={{ marginTop: '10px' }}>
                  {visibleBookies
                    .sort((a, b) => a.label.localeCompare(b.label))
                    .filter(({ removed, bookie }) => !removed || bookiesReference.includes(bookie) || bookies.includes(bookie))
                    .map(({ bookie, label, disabled, removed, clones, warning }) => {
                      const isReference = bookiesReference.includes(bookie);
                      const checked = isReference || bookies.includes(bookie);
                      const nonSelectable = selectedBookies.some(_bookie => _bookie.includes(bookie) && _bookie !== bookie);

                      return clones && (showClones || nonSelectable) ? (<>
                        <ClonesContainer>
                          <MainBookieContainer>
                            <WideBadgeComponent
                              bookie={bookie}
                              label={label}
                              disabled={disabled}
                              warning={warning}
                              removed={removed}
                              checked={checked}
                              nonSelectable={nonSelectable}
                              isReference={isReference}
                              onBookieChange={onBookieChange}
                              formik={formik}
                            />
                          </MainBookieContainer>
                          { clones
                              .filter(clone => getCloneFilter(clone, bookie)).length > 0 && <Clone2Container>
                            {
                              clones
                              .filter(clone => getCloneFilter(clone, bookie))
                              .map(clone => {
                                const removed = clone.removed;
                                const _bookie = `${bookie}:${clone.bookie}`;

                                const isReference = bookiesReference.includes(_bookie);
                                const checked = isReference || bookies.includes(_bookie);

                                const mainBookie = bookie.split(":")[0];
                                const nonSelectable = (!checked) && selectedBookies.some(_bookie => _bookie.includes(mainBookie));

                                return <WideBadgeComponent
                                  bookie={_bookie}
                                  label={clone.label}
                                  disabled={disabled}
                                  removed={removed}
                                  checked={checked}
                                  nonSelectable={nonSelectable}
                                  isReference={isReference}
                                  onBookieChange={onBookieChange}
                                  warning={warning}
                                  formik={formik}
                                />
                              })
                            }</Clone2Container> }

                        </ClonesContainer>
                      </>) : (
                        <MainBookieContainer nonClone={true}>
                          <WideBadgeComponent
                            bookie={bookie}
                            label={label}
                            disabled={disabled}
                            removed={removed}
                            checked={checked}
                            isReference={isReference}
                            onBookieChange={onBookieChange}
                            formik={formik}
                            nonClone={true}
                            warning={warning}
                          />
                        </MainBookieContainer>
                      );
                    })}
                </BookieListContainer>
              </BookiesContainer>
              <Form.Group controlId="sortBy">
                <LabelsContainer>
                <Form.Label>{t('sortBy')}</Form.Label>
                <Form.Control
                  name="sortBy"
                  as="select"
                  label="Ordena Por"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.sortBy}
                >
                  {options.sortings.map(({ type, text }) => (
                    <option key={type} value={type}>
                      {t(type)}
                    </option>
                  ))}
                </Form.Control>
                </LabelsContainer>
              </Form.Group>
              { isPre && <Form.Group controlId={"mode"}>
              <LabelsContainer>
              <Form.Label>{t('visualizationMode')}</Form.Label>
              <Form.Control
                name={"mode"}
                onChange={formik.handleChange}
                as="select"
                value={formik.values.mode}
                label="Modo visualización"
              >
                <option value={'normal'}>Normal</option>
                <option value={'freebet'}>Freebet</option>
              </Form.Control>
              </LabelsContainer>
            </Form.Group> }
              { isPre && <Form.Group controlId={"onlyLiquid"}>
              <LabelsContainer>
              <Form.Label>{t('onlyLiquidEvents')}</Form.Label>
              <HelpButton id="leagues-help" placement="right-start" width="340px">
                {t('onlyLiquidEventsHelp')}
              </HelpButton>
              <Form.Control
                name={"onlyLiquid"}
                onChange={formik.handleChange}
                as="select"
                value={formik.values.onlyLiquid}
                label="Mostrar solo eventos líquidos"
              >
                <option value={true}>{t('enable')}</option>
                <option value={false}>{t('disable')}</option>
              </Form.Control>
              </LabelsContainer>
            </Form.Group> }
             { (isLive || isPre ) && <>
              <SeparatorWrapper className="mb-1">
                <hr style={{ background: "#264226" }}/>
                <Form.Label style={{ fontWeight: "bold" }}>{t('arbitrageConfiguration')}</Form.Label>
                <hr style={{ background: "#264226" }}/>
              </SeparatorWrapper>
              <Form.Group>
                <Form.Control
                  as="select"
                  label="Configuración por arbitraje"
                  name="selectedSubscription"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={selectedSubscription}
                  style={{ background: "white", color: "#1d2d1d" }}
                >
                  <option value="default">{t('generalConfiguration')}</option>
                  {subscriptionsForOptions.map(({ subscription, text }) => (
                    <option key={subscription} value={subscription}>
                      {text}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
              {selectedSubscription !== "default" && (
                <>
                  <Form.Group>
                    <WideBadge variant="secondary" style={{ marginBottom: 0 }} forwardedAs="label">
                      <input
                        type="checkbox"
                        checked={formik.values.subscriptionConfigs[selectedSubscription] === undefined}
                        onChange={(event) => onUseDefaultSubscriptionConfigChange(event, selectedSubscription)}
                      />{" "}
                      {t('applyGeneralConfiguration')}
                    </WideBadge>
                  </Form.Group>
                  {(() => {
                    const copyableSubscriptions = subscriptionsForOptions.filter(
                      ({ subscription }) =>
                        selectedSubscription !== subscription && // do not show the same subscription
                        formik.values.subscriptionConfigs[subscription] // only show subscriptions with configs
                    );
                    if (copyableSubscriptions.length === 0) return null;
                    let selectedSubscriptionToCopy = formik.values.subscriptionToCopy;
                    // if the selected subscription to copy is not in the subscriptions to copy, select a valid one
                    if (!copyableSubscriptions.find(({ subscription }) => subscription === selectedSubscriptionToCopy))
                      selectedSubscriptionToCopy = copyableSubscriptions[0].subscription;
                    return (
                      <Form.Group>
                        <InputGroup>
                          <InputGroup.Prepend>
                            <CopyFromButton
                              variant="primary"
                              onClick={() =>
                                copySubscriptionConfiguration(selectedSubscription, selectedSubscriptionToCopy, formik)
                              }
                            >
                              {t('copyConfigurationFrom')}
                            </CopyFromButton>
                          </InputGroup.Prepend>
                          <Form.Control
                            as="select"
                            label="Copiar configuración desde:"
                            name="subscriptionToCopy"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={selectedSubscriptionToCopy}
                          >
                            {copyableSubscriptions.map(({ subscription, text }) => (
                              <option key={subscription} value={subscription}>
                                {text}
                              </option>
                            ))}
                          </Form.Control>
                        </InputGroup>
                      </Form.Group>
                    );
                  })()}
                </>
              )}

              <ConfigureArbsFormSubscription subscription={selectedSubscription} />
              </> }
             { (isLive || isPre ) && <>
              <SeparatorWrapper className="mb-1">
                <hr style={{ background: "#264226" }}/>
                <Form.Label style={{ fontWeight: "bold" }}>{t('bookieConfiguration')}</Form.Label>
                <hr style={{ background: "#264226" }}/>
              </SeparatorWrapper>
              <Form.Group>
                <Form.Control
                  as="select"
                  label="Configuración por bookie"
                  name="selectedBookie"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={selectedBookie}
                  style={{ background: "white", color: "#1d2d1d" }}
                >
                  <option value="default">Bookies</option>
                  {bookiesForOptions.map(({ bookie, text }) => (
                    <option key={bookie} value={bookie}>
                      {text}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
              {selectedBookie !== "default" && (
                <>
                  <Form.Group>
                    <WideBadge variant="secondary" style={{ marginBottom: 0 }} forwardedAs="label">
                      <input
                        type="checkbox"
                        checked={formik.values.bookiesConfigs[selectedBookie] === undefined}
                        onChange={(event) => onUseDefaultBookieConfigChange(event, selectedBookie)}
                      />{" "}
                      {t('applyDefaultConfiguration')}
                    </WideBadge>
                  </Form.Group>
                  <ConfigureArbsFormBookie bookie={selectedBookie} />
                </>
              )}
              </> }
              <ConfigureArbsSettings ref={settingsRef} {...{closeModal, formik, formLoading, setFormLoading}} />
            </fieldset>
          </Form>
        );
      }}
    </Formik>
  );
});
