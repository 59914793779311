import styled from "styled-components";

export const BookieCard = styled.div`
  min-width: 300px;
  max-width: 500px;
  flex-shrink: 2;
  border: 1px solid rgba(0,0,0,0.08);
  margin: 0px 8px 1.5em 8px;
  border-radius: 10px;
  background-color: #111E12 !important;

  @media only screen and (max-width: 700px) {
      border: 1px solid rgba(0,0,0,0.08);
      margin-bottom: 1.5em;
      border-radius: 5px;
      display: block;
      margin-right: 8px;
      margin-left: 8px;
    }
`

export const AddBookieContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const BookieName = styled.h3`
  text-transform: capitalize;
  text-align: left;
`

export const BookieNameContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

export const BookieNameBadgesContainer = styled.div`
  display: flex;
  flex-direction: column;

  .provider {
    background-color: #B8DCEF;
    color: #1C2D1C;
    padding: 10px;
  }
`

export const BookieNameBadge = styled.span`
  margin-bottom: 2px;
  text-transform: capitalize;
  padding: 3px;
  font-size: large;
`

export const BookieInformation = styled.div`
  text-align: left;

  .card-title {
    font-weight: bold;
  }
`

export const BookieWarning = styled.div`
  font-size: large;
  white-space: pre-wrap;
  margin-bottom: 5px;
`

export const AddBookieButtonContainer = styled.div`
  min-height: 80px;
  max-height: 80px;
`

export const AddBookieContainerButtons = styled.div`
  display: flex;
  justify-content: center;

  a {
    background: #E9FD8C;
    font-weight: bold;
  }

  .enableBookie {
    background-color: #94E5AB;
    color: #1C2D1C;

    :hover {
      color: white;
    }
  }

  > .addBookie{
    margin-right: 10px;
    flex-grow: 1;
  }
`