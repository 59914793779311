import React, { useState, useEffect } from "react";
import "./BotLanding.styled.js";
import i18n from "i18next";
import { useTranslation } from 'react-i18next';

import logoWithName from "../../static/logoWithName.png";

import { FooterSection } from "./FooterSection/FooterSection.js";
import { StatsSection } from "./StatsSection/StatsSection.js";
import { ArbsSection } from "./ArbsSection/ArbsSection.js";
import { useUser } from "../../hooks";

import {
  Container,
  SubContainer,
  Header,
  Logo,
  Main,
  Hero,
  HeroHeading,
  HeroSubHeading,
  NavDrop,
  HeroVideo,
  Video,
  Button,
  ContactText,
  SocialsContainer
} from './BotLanding.styled.js';

export const BotLanding = (props) => {
  const { t } = useTranslation();
  const { getBotHome } = useUser()
  const [config, setConfig] = useState(undefined);

  useEffect(async () => {
    await getBotHome().then(setConfig)
  }, [])

  return (
    <>
      <Container>
        <SubContainer>
          <Header>
            <Logo>
              <img src={logoWithName} alt="BetSmarter Logo" />
              <NavDrop title={i18n.language === 'en' ? '🇬🇧' : '🇪🇸'} id="language-dropdown">
                <NavDrop.Item onClick={() => i18n.changeLanguage('en')}>
                  🇬🇧 {t('english')}
                </NavDrop.Item>
                <NavDrop.Item onClick={() => i18n.changeLanguage('es')}>
                  🇪🇸 {t('spanish')}
                </NavDrop.Item>
              </NavDrop>
            </Logo>
          </Header>
          <Main>
            <Hero>
              <HeroHeading>BetSmarter Bot</HeroHeading>
              <HeroSubHeading>{t('botHero')}</HeroSubHeading>
              <ContactText>Obtén más información</ContactText>
              <SocialsContainer>
                <Button className="btn btn-lg" href="https://t.me/BetSmarterOficial">
                  <i className="fas fa-brands fa-telegram"></i>
                </Button>
                <Button className="btn btn-lg" href="https://wa.me/message/VSQB4PMXTPAUB1">
                  <i className="fas fa-brands fa-whatsapp"></i>
                </Button>
              </SocialsContainer>
            </Hero>
            <HeroVideo>
              <Video>
                <iframe src="https://iframe.mediadelivery.net/embed/209518/2595cb81-cddc-445c-be68-de5d9e7e5467?autoplay=true&loop=false&muted=true&preload=true&responsive=false" loading="lazy" style={{ border: '0px', top: '0px', height: '100%', width: '100%', borderRadius: '10px', position: 'absolute' }} allow="accelerometer;gyroscope;autoplay;encrypted-media;picture-in-picture;" allowFullScreen="true"></iframe>            
              </Video>
            </HeroVideo>
          </Main>
        </SubContainer>
      </Container>
      <StatsSection config={config}/>
      <ArbsSection config={config}/>
      <FooterSection />
    </>
  );
};


