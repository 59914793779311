import styled from 'styled-components';

export const ArbsSectionContainer = styled.section`
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: #111E12; // Assuming this is the background color you want
  padding: 40px 20px;
  gap: 5px;
  flex-direction: row;
  align-items: center;
  color: white;
`;

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 5px;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 1600px;

  @media (max-width: 768px) {
    flex-direction: column;
    padding: 5px;
  }
`;

export const Title = styled.h2`
  font-size: 54px;
  line-height: 1;
  max-width: 500px; // Adjust width as necessary
  margin: 0;
  font-weight: bold;
  font-family: 'Montserrat', sans-serif;
  letter-spacing: -0.08em;

  @media (max-width: 358px) {
    font-size: 34px;
  }
`;
