import React, { useCallback, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import Dropdown from "react-bootstrap/Dropdown";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import DatePicker from "react-datepicker";
import "./Card.styled";
import { useUser } from "../../hooks";
import { useUsers } from "../../contexts/UsersContext";
import {
  Balance,
  BookieSettingsContainer,
  CardBody,
  CardContainer,
  CardHeaderCustom,
  InsideInfo,
  InsideInfoTitle,
  NeedsDepositFooter,
  StyledBadge,
  UsernameText,
  BookieName,
  PopoverCustom,
} from "./Card.styled";
import { withRouter } from "react-router-dom";

const Card = ({ history, email, name, enabled, bookie, isAdmin, status, username, balance }) => {
  const { enableBookie } = useUsers();
  const { getBookieCredentials } = useUser()
  const POSSIBLE_BALANCES = ["total", "normal", "freebets"];
  const [credentials, setCredentials] = useState({});

  const [show, setShow] = useState(false);

  const editBookieInformation = () => history.push(`/bookies/${email}/${name}/edit`);

  const changeBookieStatus = useCallback(
    async () => await enableBookie(email, name, enabled),
    [email, enableBookie, enabled, name],
  );

  const checkIfErrorShouldBeShown = () =>
    isAdmin ? true : ["NewMessageError", "CredentialsError", "Error"].includes(bookie.loginError);

  const getErrorMessage = () => {
    const loginError = (bookie || {}).loginError;
    const messagesByType = {
      NewMessageError: "Mensaje pendiente en su cuenta",
      LoginError: "Error de conexión al hacer login",
      StatusCodeError: "Error de conexión al hacer login",
      CredentialsError: "USUARIO/CONTRASEÑA ERRÓNEOS",
      default: "Error al hacer login",
    };
    return messagesByType[loginError] || messagesByType.default;
  };

  const handleViewCredentials = async () => {
    const creds = await getBookieCredentials(email, name);
    setCredentials(creds);
    setShow(true);
  };

  return (
    <CardContainer className="card">
      <CardHeaderCustom className="card-header" name={name}>
        <BookieSettingsContainer>
          <BookieName>{name}</BookieName>
          <Dropdown style={{ marginLeft: '5px' }}>
            <Dropdown.Toggle variant="secondary">
              {" "}
              <i className="fas fa-cog" />
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item eventKey="1" onSelect={() => changeBookieStatus()}>
                {enabled ? "Desactivar cuenta" : "Activar cuenta"}
              </Dropdown.Item>
              <Dropdown.Item eventKey="2" onSelect={() => editBookieInformation()}>
                Editar cuenta
              </Dropdown.Item>
              {isAdmin && (
                <Dropdown.Item eventKey="3" onSelect={handleViewCredentials}>
                  Ver credenciales
                </Dropdown.Item>
              )}
            </Dropdown.Menu>
          </Dropdown>
          {bookie?.limits?.hard && (
            <StyledBadge className="badgeCustom badge">LIMITADO</StyledBadge>
          )}
          {
            <OverlayTrigger
              trigger="click"
              key="top"
              placement="top"
              overlay={
                <PopoverCustom id={`popover-positioned-top`}>
                  <PopoverCustom.Title as="h3">Información sobre {name}</PopoverCustom.Title>
                  <PopoverCustom.Content>
                    <p>
                      <strong>Logeado</strong> {status?.logged ? "Sí" : "No"}.
                    </p>
                    <p>
                      <strong>Última actualización</strong>{" "}
                      {new Date(status?.lastLoginUpdate).toLocaleString()}.
                    </p>
                    <p>
                      <strong>Reintentos de login</strong> {status?.logInRetries}.
                    </p>
                    <p>
                      <strong>Último login</strong> {new Date(status?.lastLogged).toLocaleString()}.
                    </p>
                  </PopoverCustom.Content>
                </PopoverCustom>
              }
            >
              <Button style={{ marginLeft: "5px" }} variant="secondary">
                <i className="fas fa-info-circle" />
              </Button>
            </OverlayTrigger>
          }
        </BookieSettingsContainer>
        <UsernameText name={name}>{username || ""}</UsernameText>
      </CardHeaderCustom>
      <CardBody className="card-body">
        {POSSIBLE_BALANCES.filter((balanceType) =>
          balance ? balance[balanceType] !== undefined : true,
        ).map((balanceType) => (
          <Balance>
            <InsideInfoTitle>{balanceType.toUpperCase()}</InsideInfoTitle>
            <InsideInfo>{balance ? `${balance[balanceType]}€` : "-"}</InsideInfo>
          </Balance>
        ))}
      </CardBody>
      {bookie?.loginError && checkIfErrorShouldBeShown() && (
        <div className="card-footer text-muted" style={{ textAlign: "center", background: "rgb(17, 25, 1)" }}>
            <a
              className="badgeCustom badge badge-danger"
              style={{ marginLeft: "5px", lineHeight: "2", background: "#A33" }}
            >
              {getErrorMessage()}
            </a>
        </div>
      )}
      {bookie?.needsDeposit && (
        <NeedsDepositFooter className="card-footer bg-warning" style={{ textAlign: "center" }}>
          <a style={{ fontWeight: "bold", marginBottom: "10px" }}>
            ¡Atención! Recarga el saldo inmediatamente
          </a>
        </NeedsDepositFooter>
      )}
      <Modal show={show} onHide={() => setShow(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Credenciales</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ display: "flex", flexDirection: "column" }}>
        <p>Usuario: {credentials.username}</p>
        <p>Password: {credentials.password}</p>
        </Modal.Body>
      </Modal>
    </CardContainer>
  );
};

export default withRouter(Card);
