import React from "react";
import ReactHtmlParser from "html-react-parser";
import {
    AddBookieButtonContainer, AddBookieContainer, AddBookieContainerButtons, BookieCard, BookieInformation,
    BookieName, BookieNameBadge, BookieNameBadgesContainer, BookieNameContainer, BookieWarning
} from './Bookie.styled'
import { withRouter } from 'react-router-dom'

const Bookie = ({ bookie, type = "add", toggleBookieStatus, history, match, activeBookies, bookies }) => {

    const handleAddBookie = (bookieName, type) => history.push(`/bookies/${match.params.email}/${bookieName}/${type}`);

    const getUserEnabled = (bookieName) =>
        Object.prototype.hasOwnProperty.call(activeBookies, bookieName)
            ? activeBookies[bookieName]
            : bookies.find((bookie) => bookie.name === bookieName).userEnabled;

    const getEmail = () => match.params.email;

    return (
        <BookieCard className="card bg-secondary">
            <AddBookieContainer className="card-body">
                <BookieNameContainer>
                    <BookieName className="card-title">{bookie.name}</BookieName>
                    <BookieNameBadgesContainer>
                        {bookie.openFirst && (
                            <BookieNameBadge className="badge badge-success">Abrir Primero</BookieNameBadge>
                        )}
                        {bookie.mandatory && (
                            <BookieNameBadge className="badge badge-warning">Obligatoria</BookieNameBadge>
                        )}
                        {bookie.provider && (
                            <BookieNameBadge className="badge provider">{bookie.provider}</BookieNameBadge>
                        )}
                    </BookieNameBadgesContainer>
                </BookieNameContainer>
                <BookieInformation>
                    {bookie.warning && (
                        <BookieWarning className="badge badge-warning">
                            {bookie.warning}
                        </BookieWarning>
                    )}

                    <h4 className="card-title">Registro e Ingreso</h4>
                    {bookie?.information?.register.validation && (
                        <p>
                            <i className="far fa-clock"/>{" "}
                            {ReactHtmlParser(bookie.information.register.validation)}
                        </p>
                    )}
                    {bookie?.information?.register.deposit && (
                        <p>
                            <i className="fas fa-coins"/>{" "}
                            {ReactHtmlParser(bookie.information.register.deposit)}
                        </p>
                    )}
                    {(bookie?.information?.recommendations || []).map((recommendation) => (
                            <p>
                                <a>{ReactHtmlParser(recommendation)}</a>
                            </p>
                        ))}
                </BookieInformation>
                <BookieInformation>
                    <h4 className="card-title">Retirada</h4>
                    {bookie?.information?.withdraw.waiting && (
                        <p>
                            <i className="far fa-clock"/>{" "}
                            {ReactHtmlParser(bookie?.information?.withdraw.waiting)}
                        </p>
                    )}
                    {bookie?.information?.withdraw.phone && (
                        <p>
                            <i className="fas fa-phone-alt"/>{" "}
                            {ReactHtmlParser(bookie?.information?.withdraw.phone)}
                        </p>
                    )}
                </BookieInformation>
            </AddBookieContainer>
            {getEmail() && (
                <AddBookieButtonContainer
                    className="card-body"
                >
                    <AddBookieContainerButtons>
                        <a className="btn btn-secondary addBookie" onClick={() => handleAddBookie(bookie.name, type)}>
                            {type === "add" ? "+ Añadir" : "Editar"}
                        </a>
                        {type === "edit" && (
                            <a
                                className="btn enableBookie"
                                style={{ backgroundColor: getUserEnabled(bookie.name) ? "#A33" :  "#94E5AB", color: getUserEnabled(bookie.name) ? "white" : "#1C2D1C"}}
                                onClick={() => toggleBookieStatus(bookie.name, getUserEnabled(bookie.name))}
                            >
                                {getUserEnabled(bookie.name) ? "Desactivar" : "Activar"}
                            </a>
                        )}
                    </AddBookieContainerButtons>
                </AddBookieButtonContainer>
            )}
        </BookieCard>
    );
}

export default withRouter(Bookie)
