// UserHomePage.js
import React, { useState, useEffect } from "react";
import { checkIfTokenStillValid } from "../../repositories/utils";
import { NavBarMain, UserBox } from "..";
import { Form } from "react-bootstrap";

import {
  PageContainer,
  Subcontainer, 
  HomeHeaderContainer,
  UserAlertBadge,
  CardsContainer,
  MoneyChangeBadge,
  ActivePicker,
  ActivePickerItem,
  ActivePickerItemLink,
  NameContainer,
  NameSearchContainer,
} from './BotHome.styled';

import { useUsers } from "../../contexts/UsersContext";
import { useAuth, useBilling } from "../../hooks";

// The component itself
export const BotHome = ({ history }) => {
  const [inputText, setInputText] = useState("");
  const [showActiveUsers, setShowActiveUsers] = useState(true);
  const { modifyUser } = useAuth()
  
  const { userInfo, users } = useUsers();
  const { getTotalPending, getTotalPaidGross, compareAndSetPendingPayments, getTotal } = useBilling()

  const isAdmin = userInfo?.roles?.includes("admin");

  const onChangeHandler = e => setInputText(e.target.value);

  const filterUser = user =>
    inputText !== ""
        ? ((user.bookies || []).some(bookie => bookie.username?.toLowerCase().includes(inputText.toLowerCase())) || user._id.email?.toLowerCase().includes(inputText.toLowerCase()))
        : (isAdmin ? user.enabled === showActiveUsers : true);

  useEffect(async () => {
    checkIfTokenStillValid(history);
  }, []);

  return (
    <PageContainer>
        <NavBarMain currentPage="bot" type="dashboard" history={history}/>
        <Subcontainer>
          <HomeHeaderContainer>
            <UserAlertBadge className="alert" bgColor={"black"}>
              <i className="fas fa-user" /> Usuarios <b>{users?.length}</b>
            </UserAlertBadge>
            {users?.length > 1 && (
            <NameContainer>
              <NameSearchContainer>
                <Form.Control
                  type="text"
                  value={inputText}
                  placeholder="Buscar cuenta"
                  onChange={onChangeHandler}
                />
              </NameSearchContainer>
              <Form.Label style={{ marginBottom: 0 }}><i className="fa-solid fa-magnifying-glass" /></Form.Label>
            </NameContainer>

          )}
            {userInfo?.credits && (
              <UserAlertBadge className="alert" bgColor={"black"}>
              <i className="fas fa-user"/> Créditos disponibles <b>{userInfo?.credits?.toFixed(2)}</b>
            </UserAlertBadge>
            )}
            <UserAlertBadge className="alert" bgColor={"black"}>
              <i className="fas fa-money-bill"/> Total{" "}<b>{getTotal(users)?.toFixed(2)}€</b>
              {inputText.length === 0 && (
                  <MoneyChangeBadge
                      className="badge badge-warning"
                      pendingPayments={compareAndSetPendingPayments(users)}
                  >
                    {
                      compareAndSetPendingPayments(users) >= 0 ?
                        <i className="fas fa-chevron-up" /> :
                        <i className="fas fa-chevron-down" />
                    }
                    {" "}{compareAndSetPendingPayments(users)}€
                  </MoneyChangeBadge>
              )}{" "}
            </UserAlertBadge>
            <UserAlertBadge className="alert" bgColor={"black"}>
              <i className="fas fa-money-check" /> Por Pagar{" "}
              <b>{getTotalPending(users, userInfo || {})?.toFixed(2)}€</b>
            </UserAlertBadge>
            <UserAlertBadge className="alert" bgColor={"black"}>
              <i className="fas fa-receipt"/> Pagado{" "}
              <b>{getTotalPaidGross(users)?.toFixed(2)}€</b>
            </UserAlertBadge>
          </HomeHeaderContainer>
          <HomeHeaderContainer>
          {isAdmin && users?.some((user) => !user.enabled) && (
              <ActivePicker className="nav nav-pills">
                <ActivePickerItem className="nav-item">
                  <ActivePickerItemLink
                      className={(showActiveUsers || !users?.some((user) => !user.enabled)) ? "nav-link active" : "nav-link"}
                      href="#"
                      onClick={() => setShowActiveUsers(true)}
                  >
                    USUARIOS ACTIVOS
                  </ActivePickerItemLink>
                </ActivePickerItem>
                <ActivePickerItem className="nav-item">
                  <ActivePickerItemLink
                      className={showActiveUsers ? "nav-link sss" : "nav-link active"}
                      href="#"
                      onClick={() => setShowActiveUsers(false)}
                  >
                    DESACTIVADOS
                  </ActivePickerItemLink>
                </ActivePickerItem>
              </ActivePicker>
          )}
            </HomeHeaderContainer>

          <CardsContainer>
            {users?.filter(filterUser)
                .map((user) => (<UserBox
                      adminUser={userInfo}
                      isAdmin={isAdmin}
                      modifyUser={modifyUser}
                      user={user}
                      enabled={user.enabled}
                  />
                ))}
          </CardsContainer>

      </Subcontainer>

    </PageContainer>
  );
};
