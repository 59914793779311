import React, { useState, useEffect, useMemo } from "react";
import { checkIfTokenStillValid } from "../../repositories/utils";
import { NavBarMain, UserBox } from "..";
import { Form, Table } from "react-bootstrap";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, PieChart, Pie, Cell } from 'recharts';
import { useTranslation } from 'react-i18next';

import {
  PageContainer,
  Subcontainer,
  HomeHeaderContainer,
  UserAlertBadge,
  CardsContainer,
  MoneyChangeBadge,
  ActivePicker,
  ActivePickerItem,
  ActivePickerItemLink,
  NameContainer,
  NameSearchContainer,
  ChartContainerParent,
  ChartContainer,
  ChartContainerHeader,
  UserContainer,
  TooltipContainer,
  TooltipLabel,
  TooltipItem,
  TooltipItemContainer,
  TooltipIcon,
  TooltipText,
  TooltipValue,
  TooltipSeparator,
  TooltipTotalContainer,
  TooltipTotal,
  TooltipTotalValue,
  Title,
  Value,
  DataMainContainer,
  DataContainer,
  DataContainerTitle
} from './BotStats.styled';

import { useUsers } from "../../contexts/UsersContext";
import { useBilling } from "../../hooks";

// Define colors for the pie chart
const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#AF19FF'];

// The component itself
export const BotStats = ({ history }) => {
  const [inputText, setInputText] = useState("");
  const [chartData, setChartData] = useState([]);
  const [selectedUser, setSelectedUser] = useState("all");
  const [bookieData, setBookieData] = useState([]);
  const { t } = useTranslation();

  const { users } = useUsers();

  useEffect(async () => {
    checkIfTokenStillValid(history);
  }, []);

  useEffect(() => {
    if (users) {
      const allProfitData = users.flatMap(user => 
        user.billing?.profitByDay?.map(day => ({
          date: `${day._id.year}-${String(day._id.month).padStart(2, '0')}-${String(day._id.day).padStart(2, '0')}`,
          profit: day.profit,
          expenditure: day.expenditure,
          email: day._id.email
        })) || []
      );

      const filteredData = selectedUser === "all" ? allProfitData : allProfitData.filter(data => data.email === selectedUser);

      const groupedData = filteredData.reduce((acc, curr) => {
        if (!acc[curr.date]) {
          acc[curr.date] = { date: curr.date, profit: 0, expenditure: 0 };
        }
        acc[curr.date].profit += curr.profit;
        acc[curr.date].expenditure += curr.expenditure;
        return acc;
      }, {});

      const sortedData = Object.values(groupedData).sort((a, b) => new Date(a.date) - new Date(b.date));

      // Calculate cumulative values
      let cumulativeProfit = 0;
      let cumulativeExpenditure = 0;
      const cumulativeData = sortedData.map(day => {
        cumulativeProfit += day.profit;
        cumulativeExpenditure += day.expenditure;
        return {
          ...day,
          cumulativeProfit: cumulativeProfit,
          cumulativeExpenditure: cumulativeExpenditure
        };
      });

      setChartData(cumulativeData);

      // Process bookie data for pie chart
      const allBookieData = users.flatMap(user => 
        user.billing?.bookies?.map(bookie => ({
          name: bookie._id.bookie,
          totalBets: bookie.totalBets,
          email: bookie._id.email,
          profit: bookie.profit,
          pending: bookie.pending,
          expenditure: bookie.expenditure
        })) || []
      );

      const filteredBookieData = selectedUser === "all" ? allBookieData : allBookieData.filter(data => data.email === selectedUser);

      // Aggregate bookie data
      const aggregatedBookieData = filteredBookieData.reduce((acc, curr) => {
        const existing = acc.find(item => item.name === curr.name);
        if (existing) {
          existing.totalBets += curr.totalBets;
          existing.profit += curr.profit;
          existing.pending += curr.pending;
          existing.expenditure += curr.expenditure;
        } else {
          acc.push({ name: curr.name, totalBets: curr.totalBets, profit: curr.profit, pending: curr.pending, expenditure: curr.expenditure });
        }
        return acc;
      }, []);

      setBookieData(aggregatedBookieData);
    }
  }, [users, selectedUser]);

  const totalProfit = useMemo(() => {
    if (selectedUser === "all") {
      return users?.reduce((acc, user) => acc + (user.billing?.profit || 0), 0);
    } else {
      const user = users.find(user => user._id.email === selectedUser);
      return user ? user.billing?.profit || 0 : 0;
    }
  }, [users, selectedUser]);

  const totalBets = useMemo(() => {
    if (selectedUser === "all") {
      return users?.reduce((acc, user) => acc + (user.billing?.bookies?.reduce((sum, bookie) => sum + bookie.totalBets, 0) || 0), 0);
    } else {
      const user = users.find(user => user._id.email === selectedUser);
      return user ? user.billing?.bookies?.reduce((sum, bookie) => sum + bookie.totalBets, 0) || 0 : 0;
    }
  }, [users, selectedUser]);

  const totalPending = useMemo(() => {
    if (selectedUser === "all") {
      return users?.reduce((acc, user) => acc + (user.billing?.bookies?.reduce((sum, bookie) => sum + bookie.pending, 0) || 0), 0);
    } else {
      const user = users.find(user => user._id.email === selectedUser);
      return user ? user.billing?.bookies?.reduce((sum, bookie) => sum + bookie.pending, 0) || 0 : 0;
    }
  }, [users, selectedUser]);

  const totalExpenditure = useMemo(() => {
    if (selectedUser === "all") {
      return users?.reduce((acc, user) => acc + (user.billing?.bookies?.reduce((sum, bookie) => sum + bookie.expenditure, 0) || 0), 0);
    } else {
      const user = users.find(user => user._id.email === selectedUser);
      return user ? user.billing?.bookies?.reduce((sum, bookie) => sum + bookie.expenditure, 0) || 0 : 0;
    }
  }, [users, selectedUser]);

  const totalYield = useMemo(() => {
    if (selectedUser === "all") {
      const totalProfit = users?.reduce((acc, user) => acc + (user.billing?.profit || 0), 0);
      const totalExpenditure = users?.reduce((acc, user) => acc + (user.billing?.bookies?.reduce((sum, bookie) => sum + bookie.expenditure, 0) || 0), 0);
      return totalExpenditure !== 0 ? (totalProfit / totalExpenditure) * 100 : 0;
    } else {
      const user = users?.find(user => user._id.email === selectedUser);
      if (user) {
        const totalProfit = user.billing?.profit || 0;
        const totalExpenditure = user.billing?.bookies?.reduce((sum, bookie) => sum + bookie.expenditure, 0) || 0;
        return totalExpenditure !== 0 ? (totalProfit / totalExpenditure) * 100 : 0;
      }
      return 0;
    }
  }, [users, selectedUser]);

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <TooltipContainer>
          <TooltipLabel>{new Date(label).toLocaleDateString()}</TooltipLabel>
          {payload.map((pld) => (
            <TooltipItem key={pld.dataKey}>
              <TooltipItemContainer>
                <TooltipIcon color={pld.dataKey === "cumulativeProfit" ? "#94E5AB" : "#B8DCEF"} />
                <TooltipText>{t(pld.dataKey)}</TooltipText>
              </TooltipItemContainer>
              <TooltipValue style={{ color: pld.dataKey === "cumulativeProfit" ? "#94E5AB" : "#B8DCEF" }}>{pld.payload[pld.dataKey === "cumulativeProfit" ? "profit" : "expenditure"].toFixed(2)}</TooltipValue>
            </TooltipItem>
          ))}
          <TooltipSeparator />
          <TooltipTotalContainer>
            <TooltipTotal>Total Profit</TooltipTotal>
            <TooltipTotalValue style={{ color: "#94E5AB"  }}>{payload.reduce((acc, pld) => acc + (pld.dataKey === "cumulativeProfit" ? pld.payload.cumulativeProfit : 0), 0).toFixed(2)}</TooltipTotalValue>
          </TooltipTotalContainer>
          <TooltipTotalContainer>
            <TooltipTotal>Total Expenditure</TooltipTotal>
            <TooltipTotalValue>{payload.reduce((acc, pld) => acc + (pld.dataKey === "cumulativeExpenditure" ? pld.payload.cumulativeExpenditure : 0), 0).toFixed(2)}</TooltipTotalValue>
          </TooltipTotalContainer>
        </TooltipContainer>
      );
    }

    return null;
  };

  return (
    <PageContainer>
        <NavBarMain currentPage="bot" type="stats" history={history}/>
        <Subcontainer>
        <HomeHeaderContainer>
          <UserAlertBadge className="alert" bgColor={"black"}>
            <i className="fas fa-user" /> Usuarios <b>{users?.length}</b>
          </UserAlertBadge>
          <UserContainer>
              <Form.Label style={{ marginBottom: 0 }}><i className="fa-solid fa-user" /></Form.Label>
              <Form.Control
                as="select"
                label="Users"
                name="users"
                onChange={({ target }) => setSelectedUser(target.value)}
                value={selectedUser}
                style={{ display: "inline", width: "200px", height: 33.25, marginBottom: 0 }}
              >
                <option value="all">{t('allUsers')}</option>
                {users?.map(user => (
                  <option key={user._id.email} value={user._id.email}>{user._id.email}</option>
                ))}
              </Form.Control>
            </UserContainer>
        </HomeHeaderContainer>
        <DataMainContainer>
          <DataContainer>
            <DataContainerTitle>{t('profit')}</DataContainerTitle>
            <Value style={{ color: "#94E5AB" }}>{totalProfit?.toFixed(2)}€</Value>
          </DataContainer>
          <DataContainer>
            <DataContainerTitle>{t('totalBets')}</DataContainerTitle>
            <Value>{totalBets}</Value>
          </DataContainer>
          {/* <DataContainer>
            <DataContainerTitle>Total Pending</DataContainerTitle>
            <Value>{totalPending}€</Value>
          </DataContainer> */}
          <DataContainer>
            <DataContainerTitle>{t('expenditure')}</DataContainerTitle>
            <Value>{totalExpenditure}€</Value>
          </DataContainer>
          <DataContainer>
            <DataContainerTitle>Yield</DataContainerTitle>
            <Value style={{ color: "#B8DCEF" }}>{totalYield.toFixed(2)}%</Value>
          </DataContainer>
        </DataMainContainer>
        <ChartContainerParent>
          <ChartContainer>
            <ChartContainerHeader>
              <Title>{t('profit')}</Title>
            </ChartContainerHeader>
            <ResponsiveContainer width="100%" height={450}>
              <LineChart data={chartData} margin={{ bottom: 50 }}>
                <CartesianGrid strokeDasharray="3 0" vertical={false} stroke="rgba(255, 255, 255, 0.1)" />
                <XAxis dataKey="date" stroke="white" axisLine={false} tickLine={false} tickMargin={5} angle={-45} textAnchor="end" />
                <YAxis yAxisId="left" stroke="white" axisLine={false} tickLine={false} tickMargin={5} />
                <YAxis yAxisId="right" orientation="right" stroke="white" axisLine={false} tickLine={false} tickMargin={5} />
                <Tooltip content={<CustomTooltip />} cursor={{ fill: "transparent" }} />
                {/* <Legend /> */}
                <Line yAxisId="left" type="monotone" dataKey="cumulativeProfit" name="Cumulative Profit" stroke="#94E5AB" dot={false} strokeWidth={3} />
                <Line yAxisId="right" type="monotone" dataKey="cumulativeExpenditure" name="Cumulative Expenditure" stroke="#B8DCEF" dot={false} strokeWidth={3} />
              </LineChart>
            </ResponsiveContainer>
          </ChartContainer>
        </ChartContainerParent>
        {/* <ChartContainer>
          <ChartContainerHeader>
            <Title>Bets by Bookie</Title>
          </ChartContainerHeader>
          <ResponsiveContainer width="100%" height={450}>
            <PieChart>
              <Pie
                data={bookieData}
                dataKey="totalBets"
                nameKey="name"
                cx="50%"
                cy="50%"
                outerRadius={150}
                fill="#8884d8"
                label
                minAngle={3} // Ensures all slices are visible even if they are small
              >
                {bookieData.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                ))}
              </Pie>
              <Tooltip />
            </PieChart>
          </ResponsiveContainer>
        </ChartContainer> */}
        <ChartContainer style={{ overflow: 'scroll', width: '100%' }}>
          <ChartContainerHeader>
            <Title>Bookies</Title>
          </ChartContainerHeader>
          <Table hover>
            <thead>
              <tr>
                <th>Bookie</th>
                <th>{t('profit')}</th>
                <th>{t('totalPending')}</th>
                <th>{t('expenditure')}</th>
                <th>{t('totalBets')}</th>
              </tr>
            </thead>
            <tbody>
              {bookieData
              ?.sort((a, b) => b.totalBets - a.totalBets)
              .map((bookie, index) => (
                <tr key={index} style={{ backgroundColor: index % 2 ? '#111E12' : '#1d2d1d'}}>
                  <td>{bookie.name}</td>
                  <td>{bookie.profit.toFixed(2)}€</td>
                  <td>{bookie.pending}€</td>
                  <td>{bookie.expenditure}€</td>
                  <td>{bookie.totalBets}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </ChartContainer>
      </Subcontainer>
    </PageContainer>
  );
};
