import styled from 'styled-components';
import backgroundImage from '../../../static/home/footer.png';

export const Section = styled.section`
  background-image: url(${backgroundImage});
  background-size: cover;
  background-position: center;
  color: white;
  text-align: center;
  padding: 100px 20px;
  justify-content: center;
`;

export const SubsectionContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-content: stretch;
  align-items: center;
  padding: 20px;
  flex-direction: column;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const Title = styled.h1`
  margin-bottom: 0.5em;
  font-size: 54px;
  line-height: 1;
  margin: 0;
  font-weight: bold;
  font-family: 'Armstrong', sans-serif;
  letter-spacing: -0.08em;
  max-width: 700px; 

  @media (max-width: 358px) {
    font-size: 34px;
  }

`;

export const Subtitle = styled.p`
  font-size: 1.25em;
  font-size: 1em;
  font-size: 22px;
  font-family: 'Montserrat', sans-serif;
  max-width: 700px;
  letter-spacing: -0.05em;

`;

export const CTAButton = styled.a`
    background-color: #1BB78A; // Use the green color from the design for the button
    color: #fff;
    border: none;
    padding: 15px 30px;
    font-size: 1.3em;
    cursor: pointer;
    margin-top: 30px;
    transition: background-color 0.3s;
    border-radius: 10px;

    font-family: 'Montserrat', sans-serif;
    font-weight: bold;

    &:hover {
    background-color: #16a34a;
    }
`;

export const Separator = styled.hr`
  border: 0;
  height: 1px;
  background: white;
  width: 80%;
  margin-top: 80px;
`;

export const Footer = styled.footer`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 40px;
  flex-direction: column;
`;

export const Logo = styled.img`
  max-width: 200px; // Set the max width for the logo
  margin-bottom: 20px; // Space between logo and social links
`;

export const SocialIconLink = styled.a`
  color: white;
  &:hover {
    color: #ccc;
  }
`;

export const CompanyInformation = styled.div`
display: flex;
flex-direction: column;
gap: 0px;
  `;

export const CompanyData = styled.p`
  margin: 0px;
  font-size: 14px;
font-family: 'Montserrat', sans-serif;
`