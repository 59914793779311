import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  ArbsSectionContainer,
  Container,
  Title,
} from './ArbsSection.style.js';
import { BetCard } from '../../BetCardLanding/BetCard.js';


export const ArbsSection = ({ config }) => {
  const { t } = useTranslation();

  return (
    <ArbsSectionContainer>
      <Container>
      <Title>{t('lastSurebets')}</Title>
        {config?.arbs?.map(bet => <BetCard bet={bet} />)}
      </Container>
    </ArbsSectionContainer>
  );
};
