import React from 'react';
import { useTranslation } from 'react-i18next';
import CountUp from "react-countup";

import {
  StatsSectionContainer,
  Container,
  DataContainer,
  Title,
  Data,
} from './StatsSection.style.js';

export const StatsSection = ({ config }) => {
  const { t } = useTranslation();

  return (
    <StatsSectionContainer>
      <Container>
        <DataContainer>
          <Title>{t('numberOfUsers')}</Title>
          <Data> <CountUp duration={3} className="counter" end={config?.stats?.users} separator='.'/></Data>
        </DataContainer>
        <DataContainer>
          <Title>{t('totalProfit')}</Title>
          <Data> <CountUp duration={3} className="counter" end={config?.stats?.profit} separator='.'/>€</Data>
        </DataContainer>
        <DataContainer>
          <Title>{t('averageProfitPerCustomer')}</Title>
          <Data> <CountUp duration={3} className="counter" end={config?.stats?.average} separator='.'/>€</Data>
        </DataContainer>
        <DataContainer>
          <Title>Yield</Title>
          <Data> <CountUp duration={3} className="counter" end={config?.stats?.yield} separator='.'/>%</Data>
        </DataContainer>
      </Container>
    </StatsSectionContainer>
  );
};
