// FooterSection.js
import React from 'react';
import logoWithName from "../../../static/logoWithName.png";
import { Trans, useTranslation } from 'react-i18next';
import {
  Section,
  SubsectionContainer,
  Title,
  Subtitle,
  CTAButton,
  Separator,
  Footer,
  Logo,
  CompanyInformation,
  CompanyData
} from './FooterSection.style.js';

// Component
export const FooterSection = () => {
  const { t } = useTranslation();

    return (
        <Section>
            <SubsectionContainer>
                <Title>{t('feature2')}</Title>
                <Subtitle style={{ marginTop: '30px' }}>
                <Trans >
                {t('subtitle2')}
              </Trans>
                </Subtitle>
                <CTAButton className="btn btn-lg" href="/register">{t('cta2')}</CTAButton>
                <Separator />
                <Footer>
                    <Logo src={logoWithName} alt="BetSmarter Logo" />
                    <Subtitle>
                        {t('welcomeText')}
                    </Subtitle>
                    <CompanyInformation>
                        <CompanyData>BetSmarter OÜ</CompanyData>
                        <CompanyData>16210840 © 2019-{new Date().getFullYear()}</CompanyData>
                        <CompanyData>contact@betsmarter.app</CompanyData>
                    </CompanyInformation>
                </Footer>
            </SubsectionContainer>
        </Section>
    );
};