import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import { Field, useFormikContext } from "formik";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { useSettings } from "../../hooks";
import { Fieldset, WideBadge, LabelsContainer, BadgeContainer } from "./ConfigureArbsModal.styled";
import { HelpButton } from "../HelpButton/HelpButton";
import _pull from "lodash/pull";
import { Trans, useTranslation } from "react-i18next";

export const ConfigureArbsFormSubscription = observer(({ subscription }) => {
  const { t } = useTranslation();
  const [selectedSport, setSelectedSport] = useState("football");
  const [leagueInput, setLeagueInput] = useState("");
  const { isLive, options } = useSettings();

  const { handleChange, setFieldValue, values } = useFormikContext();
  // the values of this specific subscription
  const subscriptionValues = subscription === "default" ? values : values.subscriptionConfigs[subscription];
  const namePrefix = subscription === "default" ? "" : `subscriptionConfigs.${subscription}.`;

  // Do not show fields when using the default configuration
  if (subscriptionValues === undefined) return null;

  const excludedLeagues = subscriptionValues.excludedLeagues || [];

  // Return the correct field name
  const fieldName = (name) => {
    return `${namePrefix}${name}`;
  };

  // Add a text to the list of leagues to be excluded
  const addLeagueExclusion = () => {
    if (leagueInput === undefined || leagueInput === "") return;
    if (!excludedLeagues.includes(leagueInput)) {
      excludedLeagues.push(leagueInput);
      setFieldValue(fieldName("excludedLeagues"), excludedLeagues);
    }
    setLeagueInput("");
  };

  const onLeagueInputKeyDown = (event) => {
    if (event.key == "Enter") {
      event.preventDefault();
      addLeagueExclusion();
    }
  };

  // Called when a sport is checked/unchecked
  const onSportsChange = (event) => {
    handleChange(event); // call formik's handleChange
    // Select sport on markets dropdown when it is checked so that we easily see the markets
    if (event.target.checked) setSelectedSport(event.target.value);
    else {
      // Change selected sport on markets dropdown if it has been unchecked
      if (event.target.value === selectedSport) {
        const firstSport = options.sports.find(
          ({ sport }) => sport !== selectedSport && subscriptionValues.sports.includes(sport)
        );
        setSelectedSport((firstSport || options.sports[0])?.sport);
      }
    }
  };

  const removeLeagueExclusion = (league) => {
    _pull(excludedLeagues, league);
    setFieldValue(fieldName("excludedLeagues"), excludedLeagues);
  };

  return (
    <Fieldset>
      <LabelsContainer>
        <Form.Label>{t('sports')}</Form.Label>
        <BadgeContainer role="group" aria-labelledby="checkbox-group">
          {options.sports.map(({ sport, label }) => (
            <WideBadge key={sport} variant="secondary" forwardedAs="label" checked={(values?.sports||[]).includes(sport)}>
              <Field type="checkbox" name={fieldName("sports")} value={sport} onChange={onSportsChange} /> {t(sport)}
            </WideBadge>
          ))}
        </BadgeContainer>
      </LabelsContainer>
      <LabelsContainer style={{ display: (subscriptionValues?.sports?.length ?? 0) > 0 ? null : "none" }}>
        <Form.Label>{t('markets')}</Form.Label>
        <Form.Control
          onChange={(event) => setSelectedSport(event.target.value)}
          as="select"
          value={selectedSport}
          style={{ marginBottom: "10px" }}
        >
          {options.sports.map(({ sport, label }) => (
            <option key={sport} value={sport} disabled={!subscriptionValues.sports.includes(sport)}>
              {t(sport)}
            </option>
          ))}
        </Form.Control>
        <BadgeContainer role="group" aria-labelledby="checkbox-group">
          {Object.entries(options.markets)
            .map(([sport, markets]) =>
              markets.map((market) => (
                <OverlayTrigger
                  key={`${sport}-${market}`}
                  placement="top"
                  delay={{ show: 50, hide: 200 }}
                  overlay={<Tooltip id="button-tooltip-2">{options.marketsTranslation[market] || market}</Tooltip>}
                >
                  <WideBadge
                    variant="secondary"
                    forwardedAs="label"
                    hide={sport !== selectedSport}
                    data-toggle="tooltip"
                    data-placement="top"
                    title={options.marketsTranslation[market] || market}
                    checked={(values?.sportsMarkets?.[sport]||[]).includes(market)}
                  >
                    <Field type="checkbox" name={fieldName(`sportsMarkets.${sport}`)} value={market} /> {market}
                  </WideBadge>
                </OverlayTrigger>
              ))
            )
            .flat()}
        </BadgeContainer>
      </LabelsContainer>
      <LabelsContainer>
      <Form.Label>{t('percentage')}</Form.Label>
      <div style={{ display: "flex" }}>
        <Form.Group>
          <Form.Control
            as={Field}
            placeholder="Min"
            type="number"
            name={fieldName("minPercentage")}
            min="-100"
            max="100"
          />
        </Form.Group>
        <Form.Group>
          <Form.Control
            as={Field}
            placeholder="Max"
            type="number"
            name={fieldName("maxPercentage")}
            min="-100"
            max="100"
          />
        </Form.Group>
      </div>
      </LabelsContainer>
      {isLive && (
        <>
         <LabelsContainer>
          <Form.Label>{t('surebetsTime')}</Form.Label>
          <div style={{ display: "flex" }}>
            <Form.Group>
              <Form.Control as={Field} placeholder="Min" type="number" name={fieldName("minTime")} min="0" />
            </Form.Group>
            <Form.Group>
              <Form.Control as={Field} placeholder="Max" type="number" name={fieldName("maxTime")} />
            </Form.Group>
          </div>
          </LabelsContainer>
        </>
      )}
      <Form.Group controlId={fieldName("filterOverTimeMismatch")}>
        <LabelsContainer>
        <Form.Label>{t('filterOverTimeMismatch')}</Form.Label>
        <HelpButton id="leagues-help" placement="bottom" width="340px">
          {t('filterOverTimeMismatchTooltip')}
        </HelpButton>
        <Form.Control
          name={fieldName("filterOverTimeMismatch")}
          onChange={handleChange}
          as="select"
          value={subscriptionValues.filterOverTimeMismatch}
          label="Filtrar diferentes reglas de OT"
        >
          <option value={true}>{t('enable')}</option>
          <option value={false}>{t('disable')}</option>
        </Form.Control>
        </LabelsContainer>
      </Form.Group>
      {isLive && <Form.Group controlId={fieldName("showTimeSinceLastUpdate")}>
        <LabelsContainer>
        <Form.Label>{t('timeSinceLastUpdate')}</Form.Label>
        <HelpButton id="leagues-help" placement="bottom" width="340px">
          {t('timeSinceLastUpdateHelp')}
        </HelpButton>
        <Form.Control
          name={fieldName("showTimeSinceLastUpdate")}
          onChange={handleChange}
          as="select"
          value={subscriptionValues.showTimeSinceLastUpdate}
          label="Mostrar tiempo desde la última actualización"
        >
          <option value={true}>{t('enable')}</option>
          <option value={false}>{t('disable')}</option>
        </Form.Control>
        </LabelsContainer>
      </Form.Group> }
      {isLive && <Form.Group controlId={fieldName("showNextPeriodExtraBet")}>
        <LabelsContainer>
        <Form.Label>{t('showNextPeriodExtraBet')}</Form.Label>
        <HelpButton id="leagues-help" placement="bottom" width="340px">
          {t('showNextPeriodExtraBetHelp')}
        </HelpButton>
        <Form.Control
          name={fieldName("showNextPeriodExtraBet")}
          onChange={handleChange}
          as="select"
          value={subscriptionValues.showNextPeriodExtraBet}
          label="Mostrar apuestas del próximo periodo con prioridad"
        >
          <option value={true}>{t('enable')}</option>
          <option value={false}>{t('disable')}</option>
        </Form.Control>
        </LabelsContainer>
      </Form.Group> }
      {isLive && (
        <>
        <LabelsContainer>
          <Form.Label>{t('showNextPeriod')}</Form.Label>
          <BadgeContainer role="group" aria-labelledby="checkbox-group">
            {options.nextPeriodFilteringSports.map(({ sport, label }) => (
              <WideBadge 
              key={sport} 
              variant="secondary" 
              forwardedAs="label" 
              checked={(values?.nextPeriodSports||[]).includes(sport)}
              >
                <Field type="checkbox" name={fieldName("nextPeriodSports")} value={sport} /> {t(sport)}
              </WideBadge>
            ))}
          </BadgeContainer>
          </LabelsContainer>
          <LabelsContainer>
            <Form.Label>{t('showOnlyInPauses')}</Form.Label>
            <BadgeContainer role="group" aria-labelledby="checkbox-group">
              {options.pausedFilterSports.map(({ sport, label }) => (
                <WideBadge
                  key={sport}
                  variant="secondary"
                  forwardedAs="label"
                  checked={(values?.pausedSports || []).includes(sport)}
                >
                  <Field type="checkbox" name={fieldName("pausedSports")} value={sport} /> {t(sport)}
                </WideBadge>
              ))}
            </BadgeContainer>
          </LabelsContainer>
          <LabelsContainer>
            <Form.Label><Trans i18nKey="showOnlyInPausesStrict">Mostrar solo en pausas <strong>(MODO ESTRICTO)</strong></Trans></Form.Label>
            <HelpButton id="pause-help" placement="bottom" width="340px">
              {t('showOnlyInPausesStrictHelp')}
            </HelpButton>
            <BadgeContainer role="group" aria-labelledby="checkbox-group">
              {options.pausedFilterSports.map(({ sport, label }) => (
                <WideBadge
                  key={sport}
                  variant="secondary"
                  forwardedAs="label"
                  checked={(values?.pausedSportsStrict || []).includes(sport)}
                >
                  <Field type="checkbox" name={fieldName("pausedSportsStrict")} value={sport} /> {t(sport)}
                </WideBadge>
              ))}
            </BadgeContainer>
          </LabelsContainer>
          <LabelsContainer>
          <Form.Label>{t('activateMiddleMode')}</Form.Label>
          <HelpButton id="modo-middle-help">
            {t('activateMiddleModeHelp')}
          </HelpButton>
          <BadgeContainer role="group" aria-labelledby="checkbox-group">
            {options.sports.map(({ sport, label }) => (
              <WideBadge 
                key={sport} 
                variant="secondary" 
                forwardedAs="label"
                checked={(values?.middleSports||[]).includes(sport)}
                >
                <Field type="checkbox" name={fieldName("middleSports")} value={sport} /> {t(sport)}
              </WideBadge>
            ))}
          </BadgeContainer>
          </LabelsContainer>
        </>
      )}
      <Form.Group>
        <LabelsContainer>
        <Form.Label>{t('excludeLeagues')}</Form.Label>
        <HelpButton id="leagues-help" placement="bottom" width="340px">
          {t('excludeLeaguesHelp')}
        </HelpButton>
        <BadgeContainer role="group" aria-labelledby="checkbox-group">
          {excludedLeagues.map((league) => (
            <WideBadge key={league} variant="secondary" clickable={false}>
              {league}
              <Button
                variant="outline-secondary"
                className="close"
                aria-label="Remove"
                style={{ color: "#1d2d1d" }}
                onClick={() => removeLeagueExclusion(league)}
              >
                <span aria-hidden="true">&times;</span>
              </Button>
            </WideBadge>
          ))}
        </BadgeContainer>
        <InputGroup>
          <Form.Control
            as={Field}
            placeholder={t('leagueName')}
            type="text"
            value={leagueInput}
            onChange={(event) => setLeagueInput(event.target.value)}
            onKeyDown={onLeagueInputKeyDown}
          />
          <InputGroup.Append>
            <Button variant="primary" onClick={addLeagueExclusion} style={{ background: '#E9FD8C', color: '#1d2d1d' }}>
              +
            </Button>
          </InputGroup.Append>
        </InputGroup>
      </LabelsContainer>
      </Form.Group>
    </Fieldset>
  );
});
